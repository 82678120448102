import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageLoader from "../Helper/ImageLoader";
import HomeFeatureLoader from "../Handlers/Loaders/HomeFeatureLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import Slider from "react-slick";
import { t, withTranslation } from "react-multi-lang";


const CategoryModelSec = (props) => {

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    arrow: true,
    swipeToSlide: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="home-featured-sec model-slider">
        <Container fluid>
          <Row>
            {props.home.loading ? (
              <HomeFeatureLoader />
            ) : props.home.data.categories.length > 0 ? (
              props.home.data.categories.map((category) =>
                category.models.length > 0 ? (
                  <Col md={12}>
                    <div className="home-featured-header-sec">
                      <Link
                        to={{
                          pathname: `/browse/${category.category_unique_id}`,
                          type: "category",
                        }}
                      >
                        <h1 className="slider-header-title">
                          {category.name}
                        </h1>
                      </Link>
                      <Link
                        to={{
                          pathname: `/browse/${category.category_unique_id}`,
                          type: "category",
                        }}
                      >
                        <p className="slider-header-link">{t("see_all")}</p>
                      </Link>
                    </div>
                    <Slider {...settings} className="slider-featured-sec">
                      {category.models.map((model) => (
                        <div>
                          <Link to={"/" + model.username}>
                            <div className="modal-card-item">
                              <div className="featured-card">
                                <div className="overlay-slider"></div>
                                <ImageLoader
                                  image={model.picture}
                                  alt={model.name}
                                  className="featured-img"
                                />
                                {model.is_featured ? (
                                  <span className="flag-icon-img-sec">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/images/featured.svg"
                                      }
                                      alt="user-image"
                                      className="flag-icon-img"
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                                <p className="featured-desc">
                                  {(model.product.unit_amount) ? "$"+model.product.unit_amount : ''}
                                </p>
                              </div>
                              <div className="featured-details-sec">
                                <div className="featured-details">
                                  <Link
                                    to={"/" + model.username}
                                    className="title"
                                  >
                                    {model.name}
                                  </Link>
                                </div>
                                <div className="featured-details">
                                  <Link
                                    to={"/" + model.username}
                                    className="desc"
                                  >
                                    {model.category_name}
                                  </Link>
                                  <Link
                                    to={"/" + model.username}
                                    className="desc"
                                  >
                                    {model.subcategory_name}
                                  </Link>
                                 
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </Slider>
                  </Col>
                ) : (
                  ""
                )
              )
            ) : null}
          </Row>
        </Container>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(withTranslation(CategoryModelSec));
