import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
  Table,
  Form,
  FormGroup,
} from "react-bootstrap";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { t, withTranslation } from "react-multi-lang";
import { useParams } from "react-router";
import {
  fetchModelPostsStart,
  fetchMoreModelPostsStart,
  fetchModelRatingStart,
  fetchModelSubStart,
  fetchModelSuggestionStart,
  fetchSingleModelStart,
} from "../../store/actions/ModelAction";
import { connect } from "react-redux";
import ModelProfileLoader from "../Handlers/Loaders/ModelProfileLoader";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ImageLoader from "../Helper/ImageLoader";
import AllReviewModal from "../ModelProfile/AllReviewModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router";
import { fetchCardDetailsStart } from "../../store/actions/CardAction";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { FaInstagram, FaTiktok, FaCheck } from 'react-icons/fa';
require('dotenv').config();

const SingleModelNew = (props) => {
  const sendInstrumentation = () => {
    if (selectedSub && selectedSub.id === 999999) {
      history.push({
        pathname: `/hire-as-influencer/${props.singleModel.data.user.username}/${selectedSub.model_id}/${selectedSub.id}`,
        state: { subscription_id: selectedSub },
      });
    }else {
      history.push({
        pathname: `/request-booking/${props.singleModel.data.user.username}/${selectedSub.model_id}/${selectedSub.id}`,
        state: { subscription_id: selectedSub },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 1.5,
      },
      1000: {
        items: 1.5,
      },
    },
  };

  const params = useParams();
  const history = useHistory();
  const reviewSec = useRef();
  const [selectedSub, setSelectedSub] = useState(null);
  const [allReviewModal, setAllReviewModal] = useState(false);

  const showRating = (event) => {
    event.preventDefault();

    // if (props.modelRating.loading)
    props.dispatch(
      fetchModelRatingStart({
        model_username: props.match.params.username,
      })
    );

    setAllReviewModal(true);
  };

  const handleCloseAllReviewModal = () => {
    setAllReviewModal(false);
  };

  useEffect(() => {
    props.dispatch(
      fetchSingleModelStart({
        username: params.username,
      })
    );
    props.dispatch(
      fetchModelPostsStart({
        model_username: params.username,
        skip: 0,
        take: 3,
      })
    );

    props.dispatch(fetchModelSubStart({ model_username: params.username }));

    props.dispatch(
      fetchModelSuggestionStart({
        model_username: params.username,
      })
    );
  }, [params.username]);

  const [skipFirst, setSkipFirst] = useState(true);

  useEffect(() => {
    if (!skipFirst &&
      !props.modelSub.loading &&
      Object.keys(props.modelSub.data).length > 0 &&
      props.modelSub.data.model_subscriptions.length > 0
    ) {
      const defaultSubscription = props.modelSub.data.model_subscriptions.filter((sub => sub.is_default == 1));
      if (defaultSubscription.length > 0) {
        setSelectedSub(defaultSubscription[0]);
      } else {
        setSelectedSub(props.modelSub.data.model_subscriptions[0]);
      }
    }
    setSkipFirst(false);
  }, [props.modelSub]);

  return (
    <>
      {props.singleModel.loading ? (
        <ModelProfileLoader />
      ) : (
        <>
          <div className="single-model-new-sec">
            <Container>
              <div className="single-model-new-header-sec">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/browse/${props.singleModel.data.categories.category
                      ? props.singleModel.data.categories.category
                        .category_unique_id
                      : ""
                      }`}
                  >
                    {props.singleModel.data.user.category_name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {props.singleModel.data.user.subcategory_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="single-model-new-body-sec">
                <Row>
                  <Col md={6}>
                    {props.modelPost.loading ? (
                      <Skeleton height={480} />
                    ) : (
                      <div className="single-model-new-video-slider-sec">
                        <OwlCarousel className="owl-theme" {...options}>
                          <div className="single-model-new-video-card">
                            <div className="single-model-new-video-img-sec">
                              <Image
                                src={props.singleModel.data.user.picture}
                                alt="user-image"
                                className="single-model-new-video-img"
                              />
                            </div>
                          </div>
                        </OwlCarousel>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className="single-model-new-info-sec">
                      <div className="single-model-new-info-header">
                        <div className="rating-star-model-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="#FFF400"
                            stroke="#FFF400"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            data-testid="icon-star"
                          >
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                          </svg>
                          <span>
                            {props.singleModel.data.avg_model_ratings.toFixed(
                              1
                            )}
                          </span>
                        </div>
                        <div className="all-review-count-sec">
                          <Link
                            to="#"
                            onClick={(e) =>
                              props.singleModel.data.user.total_ratings > 0
                                ? window.scrollTo({
                                  top: reviewSec.current.offsetTop - 400,
                                  behavior: "smooth",
                                })
                                : e.preventDefault()
                            }
                          >
                            {props.singleModel.data.user.total_ratings}{" "}
                            {t("reviews")}
                          </Link>
                        </div>
                      </div>
                      <div className="single-model-bio-sec">
                        <div className="single-model-bio-info">
                          <h2>{props.singleModel.data.user.name}</h2>
                          <p>{props.singleModel.data.user.subcategory_name}</p>
                          <div className="social-buttons-container">
                            {props.singleModel.data.user.instagram_followers && (
                              <Button 
                                className="social-button instagram-button"
                                onClick={() => {
                                  const instagramLink = props.singleModel.data.user.instagram_link;
                                  const sanitizedLink = instagramLink.startsWith('@') ? instagramLink.replace('@', '') : instagramLink;

                                  window.open(`https://www.instagram.com/${sanitizedLink}`, '_blank', 'noopener,noreferrer');
                                }}
                              >
                                <FaInstagram className="fa-insta"/>
                                <span className="social-buttons-span">{props.singleModel.data.user.instagram_followers}</span>
                              </Button>
                            )}
                            {props.singleModel.data.user.tiktok_followers && (
                              <Button 
                                className="social-button tiktok-button"
                                onClick={() => window.open(`https://www.tiktok.com/${props.singleModel.data.user.tiktok_link}`, '_blank')}
                              >
                                <FaTiktok className="fa-tiktok"/>
                                <span className="social-buttons-span">{props.singleModel.data.user.tiktok_followers}</span>
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="single-model-user-img-sec">
                          <ImageLoader
                            image={props.singleModel.data.user.picture}
                            className="single-model-profile-img"
                          />
                        </div>
                      </div>
                      {/*<div className="delivery-available-status">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/delivery-status.svg"
                          }
                          alt="user-image"
                          className="delivery-available-status-icon"
                        />
                        <p>{t("delivery_available")}</p>
                        </div>*/}
                      <div className="single-profile-new-model-details">
                        {props.singleModel.data.user.about &&
                        props.singleModel.data.user.about !== 'null' 
                        ? props.singleModel.data.user.about : null}
                      </div>
                      {props.modelSub.loading ? (
                        "Loading"
                      ) : props.modelSub.data.model_subscriptions &&
                        props.modelSub.data.model_subscriptions.length > 0 ? (
                        <div className="single-profile-new-model-radio-group">
                          <h4>{t("choose_subscription")}</h4>
                          <Form className="single-model-choose-option-sec">
                            {props.modelSub.data.model_subscriptions.map(
                              (subscription, index) => (
                                <Form.Group
                                  key={index}
                                  onClick={() => setSelectedSub(subscription)}
                                >
                                  <label
                                    className="card-choose-option"
                                  >
                                    <input
                                      name="plan"
                                      className="radio"
                                      type="radio"
                                      checked={selectedSub === subscription}
                                      onChange={() => setSelectedSub(subscription)}
                                      value={subscription}
                                    />
                                    <div className="card-option-box">
                                      <div className="card-option-left-sec">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/images/users-white.svg"
                                          }
                                          alt="user-image"
                                          className="users-icon"
                                        />
                                        <div className="card-option-left-info">
                                          <h5>{subscription.title}</h5>
                                          <p>{subscription.plan_formatted}</p>
                                          <p>{subscription.description}</p>
                                        </div>
                                      </div>
                                      <div className="card-option-right-sec">
                                        <h6>{subscription.unit_amount === 0 ? null : subscription.unit_amount}</h6>
                                      </div>
                                    </div>
                                  </label>
                                </Form.Group>
                              )
                            )}
                            {props.singleModel.data.user.clientInWaitList ? (
                              <Link
                                disabled  
                                to="#"                                
                                className="btn-disabled btn-edit-profile btn btn-primary"
                                style={{ backgroundColor: "var(--gray) !important", color: "var(--secondary-color) !important", borderColor: "var(--gray) !important", height: "60px"}}
                                onClick={() =>
                                  props.dispatch(
                                    createNotification(
                                      getErrorNotificationMessage(
                                        t("user_in_waitlist")
                                      )
                                    )
                                  )
                                }
                              >
                                <FaCheck className="waitlist-icon-inline" color="var(--secondary-color)" />
                                <p>{t("user_in_waitlist")}</p>
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                onClick={sendInstrumentation}
                                className="btn-edit-profile btn btn-primary"
                              >
                                {selectedSub && selectedSub.id === 999999
                                  ? t("request")
                                  : props.singleModel.data.user.request_limit_reached 
                                    ? t("wish_list")
                                    : t("buy_now")
                                }
                              </Link>
                            )}
                            
                          </Form>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              {props.singleModel.data.model_ratings.length > 0 ? (
                <div className="new-review-sec">
                  <Row>
                    <Col md={12}>
                      <div className="new-review-header-sec">
                        <h3>{t("latest_reviews")}</h3>
                        {props.singleModel.data.user.total_ratings > 2 ? (
                          <Link to="#" onClick={showRating}>
                            <p>
                              {t("see_all")}{" "}
                              {props.singleModel.data.user.total_ratings}{" "}
                              {t("reviews")}
                            </p>
                          </Link>
                        ) : null}
                      </div>
                      <div className="new-review-box">
                        {props.singleModel.data.model_ratings.map(
                          (review, i) => (
                            <div className="new-review-card">
                              <div className="new-review-info">
                                <div className="new-review-img-sec">
                                  <span>
                                    {review.username.charAt(0).toUpperCase()}
                                  </span>
                                </div>
                                <div className="new-review-details">
                                  <h6>{review.username}</h6>
                                  <p>
                                    {t("about")} {review.created_formatted}
                                  </p>
                                </div>
                              </div>
                              <div className="new-review-rating-content-sec">
                                <div className="new-review-rating-card">
                                  {[...Array(5)].map((c, i) =>
                                    i <= review.rating ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="#FFF400"
                                        stroke="#FFF400"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-testid="icon-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="#fff"
                                        stroke="#fff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-testid="icon-star"
                                      >
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                      </svg>
                                    )
                                  )}
                                </div>
                                <div className="new-review-desc-content">
                                  <p>{review.review}</p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </Container>
          </div>
        </>
      )}
      <AllReviewModal
        allReviewModal={allReviewModal}
        setAllReviewModal={setAllReviewModal}
        handleCloseAllReviewModal={handleCloseAllReviewModal}
        modelRating={props.modelRating}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singleModel: state.model.singleModel,
  modelPost: state.model.modelPost,
  modelSub: state.model.modelSub,
  modelRating: state.model.modelRating,
  modelSuggestion: state.model.modelSuggestion,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SingleModelNew));
