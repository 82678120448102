import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Image,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Tab,
} from "react-bootstrap";
import "./Wallet.css";
import {
  fetchWalletDetailsStart,
  walletHistoryStart,
  walletMoreHistoryStart,
} from "../../../store/actions/WalletAction";
import {
  fetchWithDrawalsStart,
  fetchMoreWithDrawalsStart,
} from "../../../store/actions/WithDrawAction";
import WithDrawModelSec from "../../Helper/WithDrawModelSec";
import WalletLoader from "../../Handlers/Loaders/WalletLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { t, withTranslation } from "react-multi-lang";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonLoader from "../../Handlers/Loaders/CommonLoader";

const WalletIndex = (props) => {
  const [activeTab, setActiveTab] = useState("wallet");

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    props.dispatch(
      walletHistoryStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      walletMoreHistoryStart({
        skip: props.walletHistory.data.history.length,
        take: 12,
      })
    );
  };

  useEffect(() => {
    props.dispatch(
      fetchWithDrawalsStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMore = () => {
    props.dispatch(
      fetchMoreWithDrawalsStart({
        skip: props.withDrawals.data.history.length,
        take: 12,
      })
    );
  };

  useEffect(() => {
    if (props.wallet.loading) props.dispatch(fetchWalletDetailsStart());
  }, []);

  const [show, setShow] = useState(false);

  const [shows, setShows] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="main-wrapper">
        <div className="wallet-sec">
          {props.wallet.loading ? (
            <WalletLoader />
          ) : (
            <Container>
              <Tab.Container id="left-tabs-example" defaultActiveKey="wallet">
                <Row>
                  <Col md={4} className="resp-mrg-btm-xs">
                    <div className="wallet-card">
                      <div className="wallet-icon-sec">
                        <Image
                          src={
                            window.location.origin + "/assets/images/wallet.svg"
                          }
                          alt="user-image"
                          className="wallet-icon-img"
                        />
                      </div>
                      <div className="wallet-info-sec">
                        <h4 className="title">
                          {props.wallet.data.user_wallet.total_formatted}
                        </h4>
                        <p className="desc">{t("total")}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="resp-mrg-btm-xs">
                    <div className="wallet-card">
                      <div className="wallet-icon-sec">
                        <Image
                          src={
                            window.location.origin + "/assets/images/hand.svg"
                          }
                          alt="user-image"
                          className="wallet-icon-img"
                        />
                      </div>
                      <div className="wallet-info-sec">
                        <h4 className="title">
                          {props.wallet.data.user_wallet.onhold_formatted}
                        </h4>
                        <p className="desc">{t("outstanding_balance")}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="wallet-card" style={{ padding: '2.5rem 3.5rem 2.5rem 4rem' }}>
                      <div className="wallet-icon-sec">
                        <Image
                          src={
                            window.location.origin + "/assets/images/drop.svg"
                          }
                          alt="user-image"
                          className="wallet-icon-img"
                        />
                      </div>
                      <div className="wallet-info-sec">
                        <h4 className="title">
                          {props.wallet.data.user_wallet.used_formatted}
                        </h4>
                        <p className="desc">{t("amount_received")}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                {activeTab === "wallet" && (
                  <Row>
                    <Col md={12} className="resp-mrg-btm-xs">
                      {props.walletHistory.loading ? (
                        "Loading..."
                      ) : props.walletHistory.data.history &&
                        props.walletHistory.data.history.length > 0 ? (
                        <div className="table-form table-responsive wallet-table">
                          <InfiniteScroll
                            dataLength={props.walletHistory.data.history.length}
                            next={fetchMoreData}
                            hasMore={
                              props.walletHistory.data.history.length <
                                props.walletHistory.data.total &&
                              props.walletHistory.errorCount < 2
                            }
                            loader={<CommonLoader/>}
                          >
                            <Table className="table">
                              <thead>
                                <tr>
                                  <th scope="col text-uppercase">
                                    {t("s_no")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("unique_id")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("payment_id")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("mode")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("total")}{" "}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("paid_at")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("message")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.walletHistory.data.history.map(
                                  (history, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>
                                        {history.user_wallet_payment_unique_id}
                                      </td>
                                      <td>{history.payment_id}</td>
                                      <td>{history.payment_mode}</td>
                                      {history.amount_type == "minus" ? (
                                        <td className="text-color-10">
                                          - {history.paid_amount_formatted}
                                        </td>
                                      ) : (
                                        <td className="text-success">
                                          + {history.paid_amount_formatted}
                                        </td>
                                      )}
                                      <td>{history.paid_date}</td>
                                      <td>
                                        <span className="status-icon">
                                          <i className="fa fa-check-circle"></i>
                                          {history.message}
                                        </span>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>{" "}
                          </InfiniteScroll>
                        </div>
                      ) : (
                        <NoDataFound />
                      )}
                    </Col>
                  </Row>
                )}
                {activeTab === "withdraw" && (
                  <Row>
                    <Col md={12} className="resp-mrg-btm-xs">
                      {props.withDrawals.loading ? (
                        "Loading..."
                      ) : props.withDrawals.data.history &&
                        props.withDrawals.data.history.length > 0 ? (
                        <div className="table-form table-responsive wallet-table">
                          <InfiniteScroll
                            dataLength={props.withDrawals.data.history.length}
                            next={fetchMore}
                            hasMore={
                              props.withDrawals.data.history.length <
                                props.withDrawals.data.total &&
                              props.withDrawals.errorCount < 2
                            }
                            loader={<CommonLoader/>}
                          >
                            <Table className="table">
                              <thead>
                                <tr>
                                  <th scope="col text-uppercase">
                                    {t("s_no")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("date")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("transaction_id")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("billing_account")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("requested")}{" "}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("paid")}
                                  </th>
                                  <th scope="col text-uppercase">
                                    {t("status")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.withDrawals.data.history.map(
                                  (history, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{history.created}</td>
                                      <td>
                                        {history.user_withdrawal_unique_id}
                                      </td>
                                      <td>{history.billing_account_name}</td>
                                      <td>
                                        {history.requested_amount_formatted}
                                      </td>
                                      <td>{history.paid_amount_formatted}</td>
                                      <td>{history.status_formatted}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </InfiniteScroll>
                        </div>
                      ) : (
                        <NoDataFound />
                      )}
                    </Col>
                  </Row>
                )}
              </Tab.Container>
            </Container>
          )}
        </div>
      </div>
      <WithDrawModelSec
        show={show}
        setShow={setShow}
        wallet={props.wallet}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.walletData,
  walletHistory: state.wallet.walletHistory,
  withDrawals: state.withDraw.withDrawals,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(WalletIndex));
