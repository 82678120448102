export const apiConstants = {
  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  settingsUrl: process.env.REACT_APP_API_USER_URL + "get_settings_json",

  pagesFooterUrl: process.env.REACT_APP_API_USER_URL + "static_pages",
};