// Atualize este número quando fizer mudanças significativas que exijam limpeza de cache
export const VERSION = '1.1.0';

// Configurações globais podem ser adicionadas aqui
//export const API_URL = process.env.REACT_APP_API_URL || 'https://api.seudominio.com';

// Configurações de feature flags
/*export const FEATURES = {
  NEW_USER_INTERFACE: false,
  ENABLE_NOTIFICATIONS: true,
};*/

// Configurações específicas de ambiente
//export const ENV = process.env.NODE_ENV || 'development';

// Função auxiliar para verificar o ambiente
//export const isDevelopment = () => ENV === 'development';
//export const isProduction = () => ENV === 'production';