import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Subscription.css";
import { Form, Button, Image } from "react-bootstrap";
import {
  subscriptionCreateStart,
  subscriptionViewStart,
} from "../../store/actions/SubscriptionAction";
import { t, withTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";

const EditSubscription = (props) => {
  const [inputData, setInputData] = useState({});
  const [skipRender, setSkipRender] = useState(true);
  useEffect(() => {
    props.dispatch(
      subscriptionViewStart({
        user_subscription_unique_id:
          props.match.params.user_subscription_unique_id,
      })
    );
  }, []);

  useEffect(() => {
    if (!skipRender && !props.subView.loading && Object.keys(props.subView.data).length > 0) {
      setInputData({
        ...inputData,
        id_product: props.subView.data.user_subscription.id,
        title: props.subView.data.user_subscription.title,
        description: props.subView.data.user_subscription.description,
        unit_amount: props.subView.data.user_subscription.unit_amount,
      });
    }
    setSkipRender(false);
  }, [props.subView]);
  
  const handleSubmit = (event) => { 
    event.preventDefault();
    props.dispatch(subscriptionCreateStart(inputData));
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="create-subscription-sec">
          <div className="create-subscription-card">
            <h3 className="title">Edit Subscription</h3>
            {props.subView.loading ?
              (<Skeleton count={4} height={50} />)
              :
              <Form className="create-subscription-form" onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Title*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("mathew_perry")}
                    value={inputData.title}
                    name="title"
                    onChange={(event) => {
                      setInputData({
                        ...inputData,
                        title: event.currentTarget.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Duration*</Form.Label>
                  <Form.Control
                    as="textarea" rows={7}
                    style={{ height: "initial" }}
                    placeholder={t("enter_the_Duration_in_mins")}
                    value={inputData.description}
                    name="description"
                    onChange={(event) => {
                      setInputData({
                        ...inputData,
                        description: event.currentTarget.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{t("price")}*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("enter_the_price")}
                    value={inputData.unit_amount}
                    name="unit_amount"
                    onChange={(event) => {
                      setInputData({
                        ...inputData,
                        unit_amount: event.currentTarget.value,
                      });
                    }}
                  />
                </Form.Group>
                <Button
                  className="btn-continue btn-block"
                  disabled={props.subCreate.buttonDisable}
                  type="submit"
                >
                  {props.subCreate.loadingButtonContent != null
                    ? props.subCreate.loadingButtonContent
                    : t("submit")}
                </Button>
              </Form>
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  subCreate: state.subscriptions.subCreate,
  subView: state.subscriptions.subView,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)((withTranslation(EditSubscription)));
