import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageLoader from "../Helper/ImageLoader";
import HomeFeatureLoader from "../Handlers/Loaders/HomeFeatureLoader";
import Slider from "react-slick";
import NoDataFound from "../NoDataFound/NoDataFound";
import TransactionReducer from "../../store/reducers/TransactionReducer";
import { t, withTranslation } from "react-multi-lang";

const HomeFeatured = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    arrow: true,
    swipeToSlide: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {props.featuredModel.loading ? (
        <HomeFeatureLoader />
      ) : props.featuredModel.data.models.length > 0 ? (
        <div className="home-featured-sec home-featured-new">
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="home-featured-header-sec mt-3">
                  <Link to="/browse/featured">
                    <h1 className="slider-header-title">{t("featured")}</h1>
                  </Link>
                  <Link to="/browse/featured">
                    <p className="slider-header-link">{t("see_all")}</p>
                  </Link>
                </div>
                <div className="">
                <Slider {...settings} className="slider-featured-sec">
                  {props.featuredModel.data.models.map((model) => (
                    <div>
                      <Link to={`/${model.username}`}>
                        <div className="feature-item">
                          <div className="featured-card">
                            <div className="overlay-slider"></div>
                            <ImageLoader
                              image={model.picture}
                              alt="user-image"
                              className="featured-img"
                            />
                            <span className="flag-icon-img-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/featured.svg"
                                }
                                alt="user-image"
                                className="flag-icon-img"
                              />
                            </span>
                            <p className="featured-desc">
                              {model.product_amount}
                            </p>
                          </div>
                          <div className="featured-details-sec">
                            <div className="featured-details">
                              <Link to={`/${model.username}`} className="title">
                                {model.name}
                              </Link>
                            </div>
                            <div className="featured-details">
                              <Link to={`/${model.username}`} className="desc">
                                {model.category_name}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(withTranslation(HomeFeatured));
