import React from "react";

const PurchaseFaildPage = () => {
  return (
    <div>
      <h1 className="text-center" style={{ color: "white" }}>Purchase made failed</h1>
    </div>
  );
};

export default PurchaseFaildPage;
