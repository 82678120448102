import React from "react";
import { connect } from "react-redux";
import "./NoDataFound.css";
import { Container, Image } from "react-bootstrap";

const NoDataFound = (props) => {
  return (
    <>
      <div className="main-wrapper no-data-found-sec">
        <Container>
          <div className="no-data-found-img-sec">
              <Image 
              src={
                window.location.origin + "/assets/images/no-data-img.png"
              }
              alt="user-image" className="no-data-found-img" />
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NoDataFound);
