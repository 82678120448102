import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t, withTranslation } from "react-multi-lang";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Auth.css";
import { setPasswordStart } from "../../store/actions/UserAction";

const $ = window.$;

const SetPasswordIndex = (props) => {
  useEffect(() => {
    setPasswordInputData({
      model_id: props.match.params.userId,
      password_token: props.match.params.userToken,
    });
  }, []);
  const [PasswordInputData, setPasswordInputData] = useState({});

  const handleSetPassword = (event) => {
    event.preventDefault();
    props.dispatch(setPasswordStart(PasswordInputData));
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="auth-warpper">
            <Row>
              <Col
                md={{ span: 6, offset: 3 }}
                sm={6}
                lg={{ span: 6, offset: 3 }}
                xl={{ span: 4, offset: 4 }}
              >
                <div className="login-box">
                  <h2>{t("set_password")}</h2>
                  <div className="login-footer-main-sec">
                    <span className="login-desc-text">
                     {t("welcome_to")} {configuration.get("configData.site_name")}! {t("set_password_sub_title")}
                    </span>
                  </div>
                  <Form
                    className="login-form mt-4"
                    onSubmit={handleSetPassword}
                    method="post"
                  >
                    <Form.Group controlId="formPassword" className="mb-4">
                      <Form.Label>{t("new_password")}</Form.Label>
                      <Form.Control
                        type="password"
                        value={PasswordInputData.password}
                        name="password"
                        onChange={(event) =>
                          setPasswordInputData({
                            ...PasswordInputData,
                            password: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="formPasswordConfirmation"
                      className="mb-4"
                    >
                      <Form.Label>{t("repeat_password")}</Form.Label>
                      <Form.Control
                        type="password"
                        value={PasswordInputData.password_confirmation}
                        name="password_confirmation"
                        onChange={(event) =>
                          setPasswordInputData({
                            ...PasswordInputData,
                            password_confirmation: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Button
                      className="btn-reset btn btn-auth btn-lg"
                      onClick={handleSetPassword}
                      disabled={props.setPassword.buttonDisable}
                    >
                      {props.setPassword.loadingButtonContent !== null
                        ? props.setPassword.loadingButtonContent
                        : t("set_password")}
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  setPassword: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(SetPasswordIndex));
