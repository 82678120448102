import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Subscription.css";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  Media,
  Button,
} from "react-bootstrap";
import {
  fetchSubscriptionStart,
  fetchMoreSubscriptionStart,
  subscriptionDeleteStart,
  subscriptionMakeDefaultStart,
} from "../../store/actions/SubscriptionAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Handlers/Loaders/BillingAccountLoader";
import { t, withTranslation } from "react-multi-lang";
import { confirmAlert } from 'react-confirm-alert'; 
import InfiniteScroll from "react-infinite-scroll-component";

const SubscriptionList = (props) => {


  useEffect(() => {
    props.dispatch(
      fetchSubscriptionStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreSubscriptionStart({
        skip: props.subscription.data.user_subscriptions.length,
        take: 12,
      })
    );
  };

  // useEffect(() => {
  //   props.dispatch(fetchSubscriptionStart());
  // }, []);

  const deleteSubscription = (event, subscription) => {
    event.preventDefault();
    props.dispatch(
      subscriptionDeleteStart({
        product_id: subscription.id,
        user_subscription_id: subscription.user_subscription_id,
      })
    );
  };


  const deleteConfirmation = (event, subscription) => {
    confirmAlert({
      message: t("do_you_want_to_delete_subscription_plan"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteSubscription(event, subscription)
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  const makeDefaultSubscription = (event, subscription) => {
    event.preventDefault();
    props.dispatch(
      subscriptionMakeDefaultStart({
        user_subscription_id: subscription.user_subscription_id,
      })
    );
  };
  return (
    <>
      <div className="main-wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="heading-title">{t("products")}</h3>
              <div className="create-btn-sec">
                <Link to="/create-subscription">
                  <Button className="create-btn">{t("create")}</Button>
                </Link>
              </div>
              <div className="subscription-list-card">
                {props.subscription.loading ? (
                  <BillingAccountLoader />
                ) : props.subscription.data.user_subscriptions.length > 0 ? (
                  <InfiniteScroll
                  dataLength={props.subscription.data.user_subscriptions.length}
                  next={fetchMoreData}
                  hasMore={
                    props.subscription.data.user_subscriptions.length <
                      props.subscription.data.total_subscriptions &&
                    props.subscription.errorCount < 2
                  }
                  loader={<h4>Loading...</h4>}
                >
                  <Table className="subscription-list-table">
                    <thead>
                      <tr>
                        <th>{t("title")}</th>
                        <th>{t("duration")}</th>
                        <th>{t("price")}</th>
                        <th>{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.subscription.data.user_subscriptions.map(
                        (subscription) => (
                          <tr>
                            <td>{subscription.title}</td>
                            <td>{subscription.description}</td>
                            <td>{subscription.amount_formatted}</td>{" "}
                            <td>
                              <Dropdown className="subscription-list-dropdown">
                                <Dropdown.Toggle id="dropdown-basic">
                                  <i class="fas fa-ellipsis-h"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <ul className="list-unstyled subscription-list-dropdown-item">
                                    <Media as="li">
                                      <Link
                                        to={
                                          `/edit-subscription/` +
                                          subscription.id
                                        }
                                      >
                                        {t("edits")}
                                      </Link>
                                    </Media>
                                    <Media as="li">
                                      <Link
                                        to="#"
                                        onClick={(event) =>
                                          deleteConfirmation(
                                            event,
                                            subscription
                                          )
                                        }
                                      >
                                     {t("delete")}
                                      </Link>
                                    </Media>
                                  </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  </InfiniteScroll>
                ) : (
                  <>
                   <h4 className="text-white text-center">Create the different plans for your followers to select their request</h4>
                  <NoDataFound />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  subscription: state.subscriptions.subscription,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(SubscriptionList));
