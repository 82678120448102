import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./RequestInfluencer.css";
import { Form, Button } from "react-bootstrap";
import RequestBookingCard from "../RequestBooking/RequestBookingCard";
import { fetchSingleModelStart } from "../../store/actions/ModelAction";
import { fetchOccasionStart } from "../../store/actions/LookUpAction";
import configuration from "react-global-configuration";
import { useHistory } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import { loadStripe } from "@stripe/stripe-js";
import { requestHireAsInfluencer } from "../../store/actions/WalletAction";
import Skeleton from "react-loading-skeleton";
import { FaClipboardCheck } from "react-icons/fa";
import ImageLoader from "../Helper/ImageLoader";

const RequestBookingIndex = (props) => {
  const [inComingData, setInComingData] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [amount, setAmount] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentSection, setPaymentSection] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [inputData, setInputData] = useState({
    user_subscription_id: props.match.params.subscription_id,
    model_username: props.match.params.username,
    model_id: props.match.params.model_id,
    client_type: "business",
    name: "",
    your_name: "",
    your_title: "",
    phone_number: "",
    email: "",
    request_type: "",
    message: "",
  });

  const mystyle = {
    color: "white",
  };

  const appearance = {
    theme: "night",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const h4Style = {
    marginBottom: "20px",
  };

  const history = useHistory();

  const routeChange = () => {
    history.goBack();
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevParams = usePrevious(props.match.params.username);

  useEffect(() => {
    props.dispatch(fetchOccasionStart());
  }, []);

  useEffect(() => {
    if (
      props.location.model !== undefined &&
      props.location.subscription !== undefined
    ) {
      setInComingData(true);
    } else {
      if (prevParams !== props.match.params.username) {
        props.dispatch(
          fetchSingleModelStart({
            username: props.match.params.username,
            isRequestBookingCard: 1,
            product_id: props.match.params.subscription_id,
          })
        );
      }
    }
  }, [prevParams, props.match.params.username]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.singleSubscription.loading &&
      Object.keys(props.singleSubscription.data).length > 0
    ) {
      setAmount(props.singleSubscription.data.subscription.amount);
    }
    setSkipRender(false);
  }, [props.singleSubscription]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await props.dispatch(requestHireAsInfluencer({ ...inputData }));
      setIsSuccess(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isSuccess) {
    return (
      <div className="wish-list-message">
        <div className="waitlist-icon">
          <FaClipboardCheck size={48} color="var(--primary-color)" />
        </div>
        <h4 style={h4Style}>{t("request_hire_thank_you")}</h4>
        <p>
          {t("request_hire_received")}
          <br />
          <br />
          {t("request_hire_explore_others")}
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="main-wrapper">
        <div className="request-booking-sec">
          <Form className="request-bread">
            <span className={`${!paymentSection ? "active" : ""}`}>
              1. {t("request")}
            </span>
          </Form>
          {!paymentSection ? (
            <>
              {props.singleModel.loading ? (
                <div className="request-booking-card">
                  <div className="request-booking-profile-sec">
                    <Skeleton circle={true} count={1} height={80} width={80} />
                    <p className="profile-name">
                      <Skeleton count={1} height={26} width={280} />
                    </p>
                  </div>
                  <div className="request-book-tab-sec">
                    <p className="sub-title">
                      {" "}
                      <Skeleton count={1} height={26} width={130} />
                    </p>
                    <div className="radio-nav-tab-list-sec mb-3">
                      <div className="request-felx">
                        <Skeleton
                          circle={true}
                          count={1}
                          height={92}
                          width={92}
                        />
                      </div>
                      <div className="request-felx">
                        <Skeleton
                          circle={true}
                          count={1}
                          height={92}
                          width={92}
                        />
                      </div>
                      <div className="request-felx">
                        <Skeleton
                          circle={true}
                          count={1}
                          height={92}
                          width={92}
                        />
                      </div>
                    </div>
                    <div className="request-book-tab-content-sec">
                      <Skeleton
                        className="mb-3"
                        count={3}
                        height={40}
                        width={560}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="request-booking-card">
                  <div className="request-booking-profile-sec">
                    <ImageLoader
                      image={props.singleModel.data.user.picture}
                      alt="user-image"
                      className="user-img"
                    />
                    <p className="profile-name">
                      {t("new_request_for")} {props.singleModel.data.user.name}
                    </p>
                  </div>
                  <div className="request-book-tab-sec">
                    <div className="request-nav-sec">
                      <div className="request-book-tab-content-sec">
                        <Form onSubmit={handleSubmit}>
                          <div className="request-booking-form">
                            <Form.Group controlId="clientType">
                              <Form.Label>{t("client_type")}: *</Form.Label>
                              <Form.Control
                                as="select"
                                name="client_type"
                                value={inputData.client_type}
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    client_type: e.target.value,
                                  })
                                }
                                required
                              >
                                <option value="">{t("select")}</option>
                                <option value="personal">
                                  {t("personal")}
                                </option>
                                <option value="business">
                                  {t("business")}
                                </option>
                              </Form.Control>
                            </Form.Group>

                            {inputData.client_type === "personal" && (
                              <div>
                                <Form.Group controlId="name">
                                  <Form.Label>{t("name")}: *</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    value={inputData.name}
                                    onChange={(e) =>
                                      setInputData({
                                        ...inputData,
                                        name: e.target.value,
                                      })
                                    }
                                    required
                                  />
                                </Form.Group>
                              </div>
                            )}

                            {inputData.client_type === "business" && (
                              <div>
                                <Form.Group controlId="name_company">
                                  <Form.Label>{t("name_company")}: *</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    value={inputData.name}
                                    onChange={(e) =>
                                      setInputData({
                                        ...inputData,
                                        name: e.target.value,
                                      })
                                    }
                                    required={inputData.client_type === "business"}
                                  />
                                </Form.Group>
                                <Form.Group controlId="your_name">
                                  <Form.Label>
                                    {t("your_name")}: *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="your_name"
                                    value={inputData.your_name}
                                    onChange={(e) =>
                                      setInputData({
                                        ...inputData,
                                        your_name: e.target.value,
                                      })
                                    }
                                    required={inputData.client_type === "business"}
                                  />
                                </Form.Group>
                                <Form.Group controlId="your_title">
                                  <Form.Label>
                                    {t("your_title")}: *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="your_title"
                                    value={inputData.your_title}
                                    onChange={(e) =>
                                      setInputData({
                                        ...inputData,
                                        your_title: e.target.value,
                                      })
                                    }
                                    required={inputData.client_type === "business"}
                                  />
                                </Form.Group>
                              </div>
                            )}

                            <Form.Group controlId="phone_number">
                              <Form.Label>{t("phone_number")}: *</Form.Label>
                              <Form.Control
                                type="tel"
                                name="phone_number"
                                value={inputData.phone_number}
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    phone_number: e.target.value,
                                  })
                                }
                                required
                              />
                            </Form.Group>

                            <Form.Group controlId="email">
                              <Form.Label>{t("email")}: *</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                value={inputData.email}
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    email: e.target.value,
                                  })
                                }
                                required
                              />
                            </Form.Group>

                            <Form.Group controlId="request_type">
                              <Form.Label>{t("request_type")}: *</Form.Label>
                              <Form.Control
                                as="select"
                                name="request_type"
                                value={inputData.request_type}
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    request_type: e.target.value,
                                  })
                                }
                                required
                              >
                                <option value="">{t("select")}</option>
                                <option value="custom_video">
                                  {t("custom_video")}
                                </option>
                                <option value="event_appearance">
                                  {t("event_appearance")}
                                </option>
                                <option value="ad_campaign">
                                  {t("ad_campaign")}
                                </option>
                                <option value="other">{t("other")}</option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="message">
                              <Form.Label>{t("describe_your_request_in_as_much_detail_as_possible")}: *</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={10}
                                className="height-auto-form"
                                name="message"
                                value={inputData.message}
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    message: e.target.value,
                                  })
                                }
                                required
                              />
                            </Form.Group>

                            <Button type="submit">{t("request")}</Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <small className="request-booking-bottom-text">
                {t("by_booking_you_agree_to")}{" "}
                {configuration.get("configData.site_name")}
                's
                <a href="/page/terms" target="_blank">
                  {t("terms_service")}
                </a>
                <span>
                  {" "}
                  {t("and")}
                  <a href="/page/privacy" target="_blank">
                    {t("privacy_policy")}
                  </a>
                </span>
              </small>
            </>
          ) : (
            <div
              className="request-booking-card payment-form"
              style={mystyle}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleModel: state.model.singleModel,
  occasion: state.lookup.occasion,
  saveBooking: state.bookings.saveBooking,
  singleSubscription: state.subscriptions.singleSubscription,
  generateStripe: state.wallet.generateStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(RequestBookingIndex));
