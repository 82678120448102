import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { t, withTranslation } from "react-multi-lang";


import "./Auth.css";
import {
  usernameAvailablityStart,
  userRegisterStart,
} from "../../store/actions/UserAction";
import SocialButton from "../Helper/SocialButton";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const $ = window.$;

const SignupIndex = (props) => {


  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const registerSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name_is_required")),
    username: Yup.string()
      .required(t("user_name_is_required")),

    email: Yup.string()
      .email(t("invalid_email_note"))
      .required(t("email_is_required")),
    password: Yup.string()
      .min(6, t("must_contain_6_characters"))
      .required(t("password_is_required"))
    // .matches(
    //     /^(?=.*[a-zA-Z])(?=.{6,})/,
    //     t("must_contain_6_characters")
    // ),
  });

  const [signupInputData, setSignupInputData] = useState({
    username: "",
  });

  const handleSignup = (values) => {
    // console.log(values);
    props.dispatch(userRegisterStart(values));
  };



  const usernameCheck = (event) => {
    setSignupInputData({
      ...signupInputData,
      username: event.currentTarget.value,
    });
    if (signupInputData.username.length > 4)
      props.dispatch(
        usernameAvailablityStart({
          username: event.currentTarget.value,
        })
      );
  };


  const handleUsernameValidation = (username) => {
    if (username && username.length > 4) {
      if (username !== username) {
        console.log("Enter User validation ")
        setSignupInputData(username);
        props.dispatch(usernameAvailablityStart({ username: username }));
        return "";
      }
    } else {
      return t("must_contain_4_characters");
    }
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  useEffect(() => { }, []);

  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="auth-warpper">
            <Row>
              <Col
                md={{ span: 6, offset: 3 }}
                sm={6}
                lg={{ span: 6, offset: 3 }}
                xl={{ span: 6, offset: 3 }}
              >
                <div className="login-box">
                  <h2>
                    {t("welcome_to")} {configuration.get("configData.site_name")}
                  </h2>
                  <p>
                    {t("signup_sub_title")}
                  </p>
                  <div className="login-type-sec">
                    {configuration.get("configData.FB_CLIENT_ID") ? (
                      <SocialButton
                        provider="facebook"
                        appId={configuration.get("configData.FB_CLIENT_ID")}
                        onLoginSuccess={handleFacebookLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className="btn-facebook"
                        id="facebook-connect"
                      >
                        <span>{t("login_with_facebook")}</span>
                      </SocialButton>
                    ) : (
                      ""
                    )}
                    {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                      <SocialButton
                        provider="google"
                        key={"google"}
                        appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                        onLoginSuccess={handleGoogleLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className="btn-apple"
                        id="google-connect"
                      >
                        <span>{t("login_with_google")}</span>
                      </SocialButton>
                    ) : (
                      ""
                    )}
                  </div>

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                    configuration.get("configData.FB_CLIENT_ID") ? (
                    <div className="or-sec">
                      <span className="or-text">OR</span>
                    </div>
                  ) : (
                    <span classsName="login-or-hide"></span>
                  )}

                  <Formik
                    initialValues={{
                      name: "",
                      username: "",
                      email: "",
                      password: "",

                    }}
                    validationSchema={registerSchema}
                    onSubmit={(values) => handleSignup(values)}>

                    {({ touched, errors, values, isSubmitting, setFieldValue }) => (
                      <Form className="login-form" noValidate>

                        <label className="form-label">{t("name")}</label>
                        <Field
                          type="text"
                          autoFocus
                          name="name"
                          className="form-control"
                          value={values.name}

                        />

                        <ErrorMessage component={"div"} name="name" className='text-danger text-right' />

                        <label className="form-label">{t("username")}</label>
                        <Field
                          type="text"
                          className="form-control"
                          value={values.username}
                          name="username"

                        />
                        <ErrorMessage component={"div"} name="username" className='text-danger text-right' />

                        <label className="form-label">{t("email")}</label>
                        <Field
                          type="email"
                          className="form-control"
                          validate={handleUsernameValidation}
                          name="email"
                          value={values.email}

                        />
                        <ErrorMessage component={"div"} name="email" className='text-danger text-right' />

                        <label className="form-label">{t("password")}</label>
                        <Field
                          type={passwordShown ? "text" : "password"}
                          className="form-control"
                          value={values.password}
                          name="password"

                        />
                        <i
                          className="register-toggle-password"
                          onClick={togglePasswordVisiblity}
                        >
                          {passwordShown ? eyeSlash : eye}
                        </i>{" "}
                        <ErrorMessage component={"div"} name="password" className='text-danger text-right' />

                        <Button
                          type="submit"
                          className="btn-email mt-3"
                          disabled={props.signup.buttonDisable ||
                            !values.name ||
                            !values.username ||
                            !values.email ||
                            !values.password}

                        >
                          {props.signup.loadingButtonContent !== null
                            ? props.signup.loadingButtonContent
                            : t("signup")}
                        </Button>
                      </Form>

                    )}
                  </Formik>



                  <div className="login-footer-sub-sec-1">
                    <span className="login-desc-text-1">
                      <Link to="/login">{t("already_have_an_account")}</Link>
                    </span>
                  </div>

                  {/* <Form className="signup-check-form hidden">
                    <Form.Check
                      type="checkbox"
                      id="customControlAutosizing"
                      label="Email me exclusive offers and discounts"
                      custom
                    />
                  </Form> */}

                  <div className="login-footer-main-sec-1">
                    <span className="login-desc-text">
                      {t("signup_sub_note")} {" "}
                      {configuration.get("configData.site_name")} 's
                      <Link to="/page/terms" className="txt-underline">
                        {" "}
                        {t("terms_of_service")}
                      </Link>{" "}
                      {t("and")}
                      <Link to="/page/privacy" className="txt-underline">
                        {" "}
                        {t("privacy_policy")}
                      </Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  signup: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(SignupIndex));
