import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModelListSecLoader from "../Handlers/Loaders/ModelListSecLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import { t, withTranslation } from "react-multi-lang";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

const ModelListSec = (props) => {

  return (
    <>
      <div className="home-featured-sec">
        <div className="home-featured-header-sec">
          <Link to="#">
            <h1 className="slider-header-title"> 
              {t("all_in")} <span className="text-capitalize">{props.model.data.name_filter_category}</span>
            </h1>
          </Link>
        </div>
        <div className="slider-all-sec">

          {props.model.loading ? (
            <Row className="justify-content-md-center">
              {[...Array(4)].map((_, index) => (
                <Col md={6} xl={3} lg={4} key={`skeleton-${index}`}>
                  <Skeleton width={320} height={225} />
                  <Skeleton count={1} width={100} height={25} />
                  <Skeleton count={1} width={60} height={25} />
                </Col>
              ))}
            </Row>
          ) : props.model.data.models.length > 0 ? (
            <InfiniteScroll
              dataLength={props.model.data.models.length}
              next={props.fetchMoreModel}
              hasMore={props.model.data.models.length < props.model.data.total &&
                props.model.errorCount < 2}
              loader={<Row className="justify-content-md-center">
                <ModelListSecLoader />
              </Row>}
              style={{ overflow: "unset" }}
            >
              <Row className="justify-content-md-center">
                {props.model.data.models.map((model) => (
                  <Col md={6} xl={3} lg={4} key={model.id}>
                    <div>
                      <Link to={`/${model.username}`}>
                        <div className="feature-item">
                          <div className="featured-card">
                            <div className="overlay-slider"></div>
                            <Image
                              src={model.picture}
                              alt="user-image"
                              className="featured-img"
                            />
                            {model.is_featured ? (
                              <span className="flag-icon-img-sec">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/featured.svg"
                                  }
                                  alt="user-image"
                                  className="flag-icon-img"
                                />
                              </span>
                            ) : null}
                            <p className="featured-desc">
                              {model.product_amount}
                            </p>
                          </div>
                          <div className="featured-details-sec">
                            <div className="featured-details">
                              <Link to={`/${model.username}`} className="title">
                                {model.name}
                              </Link>
                            </div>
                            <div className="featured-details">
                              <Link to="#" className="desc">
                                {model.category_name}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(withTranslation(ModelListSec));
