import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Slider from "react-slick";
import { t, withTranslation } from "react-multi-lang";

const HomeCategory = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    arrow: true,
    swipeToSlide: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1195,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {!props.category.loading &&
        props.category.data.categories &&
        props.category.data.categories.length > 0 ?
        <div className="home-category-new-sec">
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="home-featured-header-sec">
                  <h1 className="slider-header-title">{t("categories")}</h1>
                  <Link to="/browse/featured">
                    <p className="slider-header-link slider-end">{t("see_all")}</p>
                  </Link>
                </div>
                <div className="category-card-tab">
                  <Slider {...settings} className="category-home-slider-sec">
                    {props.category.data.categories.map((category, i) =>
                      <Link to={`/browse/${category.category_unique_id}`}>
                        <div className="category-card" key={i}>
                          <div className={`category-slider-bg card-color-${(i % 6) + 1}`}>
                            <div className="category-list">
                              <div className="category-image">
                                {category.recent_models.slice(0, 3).map((model, j) => (
                                  <div className={`cat-image-${j + 1}`} key={j}>
                                    <img src={model.picture} alt={`Category ${i} Image ${j}`} />
                                  </div>
                                ))}
                              </div>
                              <div className="category-image-content">
                                {category.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </div> : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  category: state.lookup.category,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(HomeCategory));
