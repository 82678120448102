import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import "./Enroll.css";
import { Button, Form, FormGroup, Image,Row, Col } from "react-bootstrap";
import { modelEnrollStart } from "../../store/actions/UserAction";
import { t, withTranslation } from "react-multi-lang";

const EnrollIndex = (props) => {
  const [inputData, setInputData] = useState({});
  const [imagePreview, setImagePreview] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(modelEnrollStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="enroll-sec">
          <div className="enroll-card">
            <h3 className="title">
              {t("apply_to_join")} {configuration.get("configData.site_name")} {" "} {t("as_talent")}
            </h3>
            <p className="desc">
              {t("if_you_have_fanbase_and_want_to_join")} {" "}
              {configuration.get("configData.site_name")} {t("talent_note")}
            </p>
            <Form className="enroll-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("name")}*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={inputData.name}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      name: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.name ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("email")}*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={inputData.email}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      email: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.email ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("phone_number_never_shared")}*</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={inputData.mobile}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      mobile: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.mobile ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="pronoun-select">
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/select-arrow-white.svg"
                  }
                  alt="user-image"
                  className="select-arrow"
                />
                <Form.Label>{t("where_can_we_find_you")} *</Form.Label>
                <Form.Control
                  as="select"
                  name="platform"
                  value={inputData.platform}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      platform: event.currentTarget.value,
                    })
                  }
                >
                  <option>{t("select")}</option>
                  <option value="twitter">{t("twitter")}</option>
                  <option value="instagram">{t("instagram")}</option>
                  <option value="youtube">{t("youtube")}</option>
                  <option value="facebook">{t("facebook")}</option>
                  <option value="twitch">{t("twitch")}</option>
                  <option value="tiktok">{t("tikTok")}</option>
                  <option value="others">{t("others")}</option>

                </Form.Control>
               { !inputData.platform ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("your_handle")} *</Form.Label>
                <Form.Control
                  type="text"
                  name="platform_username"
                  value={inputData.platform_username}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      platform_username: event.currentTarget.value,
                    })
                  }
                />
                {!inputData.platform_username ? <Form.Text className="short-desc">{t("required")}</Form.Text> : null}
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("anything_else_we_should_know_about_you")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="height-auto-form"
                  name="description"
                  value={inputData.description}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      description: event.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
              <FormGroup controlId="formBasicDocumentFile">
                <Form.Label>{t("upload_image")}*</Form.Label>
                <Form.Control
                  type="file"
                  name="document_file"
                  accept="application/pdf,image/*"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    setInputData({
                      ...inputData,
                      document_file: file,
                    })

                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setImagePreview(reader.result);
                    };

                    if (file) {
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                {!inputData.image ? (
                  <Form.Text className="short-desc">{t("required")}</Form.Text>
                ) : null}
              </FormGroup>

              {imagePreview && (
                <Row>
                  <Col>
                    {inputData.document_file.type === "application/pdf" ? (
                      <embed src={imagePreview} type="application/pdf" width="100%" height="500px" />
                    ) : (
                      <Image src={imagePreview} thumbnail />
                    )}
                  </Col>
                </Row>
              )}

              <div className="text-center">
                <small className="enroll-text">
                  {t("enroll_note")}{" "}
                  {configuration.get("configData.site_name")}. {t("enroll_sub_note")}
                </small>
              </div>
              <Button
                className="btn-continue btn-block"
                type="submit"
                disabled={
                  !inputData.name ||
                  !inputData.email ||
                  !inputData.mobile ||
                  !inputData.platform_username
                }
              >
                {props.modelEnroll.loadingButtonContent != null
                  ? props.modelEnroll.loadingButtonContent
                  : t("submit")}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  modelEnroll: state.users.modelEnroll,
  kycDocDetails: state.kycDocument.kycDocDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EnrollIndex));
