import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import "./Dashboard.css";
import { Container, Row, Col, Image, Table } from "react-bootstrap";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import ImageLoader from "../Helper/ImageLoader";
import DashboardLoader from "../Handlers/Loaders/DashboardLoader";
import { t, withTranslation } from "react-multi-lang";

const DashboardIndex = (props) => {
  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  return (
    <>
      <div className="main-wrapper dashboard-wrapper">
        {props.profile.loading ? (
          <DashboardLoader />
        ) : (
          <div className="dashboard-sec">
            <Container>
              <Row>
                <Col md={4}>
                  <div className="profile-img-sec">
                    <ImageLoader
                      image={props.profile.data.picture}
                      className="user-img"
                    />
                  </div>
                  <div className="edit-avatar-sec">
                    <Link to="/edit-profile">{t("edit_avatar")}</Link>
                  </div>
                </Col>
                <Col md={8} hidden>
                  <div className="user-balance-sec">
                    <p className="invite-amount">
                      <Link to="#">{t("get")} $5</Link>
                    </p>
                    <p className="invite-desc">
                    {t("invite_friends_to_join")}{" "}
                      {configuration.get("configData.site_name")}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col ms={12}>
                  <Table
                    className="table table-striped dashboard-table mt-3"
                    responsive
                  >
                    <tbody>
                      <tr>
                        <th>{t("your_name")}</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                {props.profile.data.name}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">{t("edit")}</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>{t("your_username")}</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                @{props.profile.data.username}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">{t("edit")}</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <th>{t("your_email")}</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                {props.profile.data.email}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">{t("edit")}</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>
                      {props.profile.data.is_model ===  2 ? (
                        <tr>
                          <th>{t("your_request_limit_per_month")}</th>
                          <td>
                            <span>
                              <span>
                                <span class="data-dynamic">
                                  {props.profile.data.request_limit}
                                </span>
                                <Link to="/edit-profile">
                                  <span class="data-static">{t("edit")}</span>
                                </Link>
                              </span>
                            </span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {props.profile.data.is_model ===  2 ? (
                        <tr>
                          <th>{t("hire_as_influencer")}</th>
                          <td>
                            <span>
                              <span>
                                <span class="data-dynamic">
                                  {props.profile.data.hire_as_influencer === 1
                                    ? t("Yes")
                                    : t("No")}
                                </span>
                                <Link to="/edit-profile">
                                  <span class="data-static">{t("edit")}</span>
                                </Link>
                              </span>
                            </span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <th>{t("your_bio")}</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                {props.profile.data.about}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">{t("edit")}</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>
                      {props.profile.data.is_model ===  2 ? (
                        <tr>
                          <th>{t("instagram")}</th>
                          <td>
                            <span>
                              <span>
                                <span class="data-dynamic">
                                  {props.profile.data.instagram_link}
                                </span>
                                <Link to="/edit-profile">
                                  <span class="data-static">{t("edit")}</span>
                                </Link>
                              </span>
                            </span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {props.profile.data.is_model ===  2 ? (
                        <tr>
                          <th>{t("tikTok")}</th>
                          <td>
                            <span>
                              <span>
                                <span class="data-dynamic">
                                  {props.profile.data.tiktok_link}
                                </span>
                                <Link to="/edit-profile">
                                  <span class="data-static">{t("edit")}</span>
                                </Link>
                              </span>
                            </span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <th>{t("your_category")}</th>
                        <td>
                          {props.profile.data.category_name} -{" "}
                          {props.profile.data.subcategory_name}
                          {props.profile.data.is_model ?
                          <Link to="/categories">
                            <span class="data-static">{t("update_category")}</span>
                          </Link>
                          : ''}
                        </td>
                      </tr>
                      <tr>
                        <th>{t("legal")}</th>
                        <td>
                          <Link to="/page/terms">
                            <span class="data-static">{t("terms")}</span>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(DashboardIndex));
