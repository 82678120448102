export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const USERNAME_AVAILABILITY_START = "USERNAME_AVAILABILITY_START";
export const USERNAME_AVAILABILITY_SUCCESS = "USERNAME_AVAILABILITY_SUCCESS";
export const USERNAME_AVAILABILITY_FAILURE = "USERNAME_AVAILABILITY_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const SET_PASSWORD_START = "SET_PASSWORD_START";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const FETCH_PAYMENTS_START = "FETCH_PAYMENTS_START";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

export const MODEL_ENROLL_START = "MODEL_ENROLL_START";
export const MODEL_ENROLL_SUCCESS = "MODEL_ENROLL_SUCCESS";
export const MODEL_ENROLL_FAILURE = "MODEL_ENROLL_FAILURE";

// Cards constants

export const FETCH_CARDS_START = "FETCH_CARDS_START";
export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";
export const FETCH_CARDS_FAILURE = "FETCH_CARDS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

// Bank account
export const ADD_BILLING_ACCOUNT_START = "ADD_BILLING_ACCOUNT_START";
export const ADD_BILLING_ACCOUNT_SUCCESS = "ADD_BILLING_ACCOUNT_SUCCESS";
export const ADD_BILLING_ACCOUNT_FAILURE = "ADD_BILLING_ACCOUNT_FAILURE";

export const ADD_BILLING_ACCOUNT_DATA = "ADD_BILLING_ACCOUNT_DATA";

export const GET_BILLING_ACCOUNT_START = "GET_BILLING_ACCOUNT_START";
export const GET_BILLING_ACCOUNT_SUCCESS = "GET_BILLING_ACCOUNT_SUCCESS";
export const GET_BILLING_ACCOUNT_FAILURE = "GET_BILLING_ACCOUNT_FAILURE";

export const DELETE_BILLING_ACCOUNT_START = "DELETE_BILLING_ACCOUNT_START";
export const DELETE_BILLING_ACCOUNT_SUCCESS = "DELETE_BILLING_ACCOUNT_SUCCESS";
export const DELETE_BILLING_ACCOUNT_FAILURE = "DELETE_BILLING_ACCOUNT_FAILURE";

export const FETCH_SINGLE_BILLING_ACCOUNT_START =
  "FETCH_SINGLE_BILLING_ACCOUNT_START";
export const FETCH_SINGLE_BILLING_ACCOUNT_SUCCESS =
  "FETCH_SINGLE_BILLING_ACCOUNT_SUCCESS";
export const FETCH_SINGLE_BILLING_ACCOUNT_FAILURE =
  "FETCH_SINGLE_BILLING_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BILLING_ACCOUNT_START =
  "MAKE_DEFAULT_BILLING_ACCOUNT_START";
export const MAKE_DEFAULT_BILLING_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BILLING_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BILLING_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BILLING_ACCOUNT_FAILURE";

// Wallet constants
export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const ADD_MONEY_VIA_CARD_START = "ADD_MONEY_VIA_CARD_START";
export const ADD_MONEY_VIA_CARD_SUCCESS = "ADD_MONEY_VIA_CARD_SUCCESS";
export const ADD_MONEY_VIA_CARD_FAILURE = "ADD_MONEY_VIA_CARD_FAILURE";

export const ADD_MONEY_VIA_BANK_START = "ADD_MONEY_VIA_BANK_START";
export const ADD_MONEY_VIA_BANK_SUCCESS = "ADD_MONEY_VIA_BANK_SUCCESS";
export const ADD_MONEY_VIA_BANK_FAILURE = "ADD_MONEY_VIA_BANK_FAILURE";

export const WALLET_HISTORY_START = "WALLET_HISTORY_START";
export const WALLET_HISTORY_SUCCESS = "WALLET_HISTORY_SUCCESS";
export const WALLET_HISTORY_FAILURE = "WALLET_HISTORY_FAILURE";
export const WALLET_MORE_HISTORY_START = "WALLET_MORE_HISTORY_START";

// Withdraw constants

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";

export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";
export const FETCH_MORE_WITHDRAWALS_START = "FETCH_MORE_WITHDRAWALS_START";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS =
  "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE =
  "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const SEARCH_WITHDRAWALS_START = "SEARCH_WITHDRAWALS_START";
export const SEARCH_WITHDRAWALS_SUCCESS = "SEARCH_WITHDRAWALS_SUCCESS";
export const SEARCH_WITHDRAWALS_FAILURE = "SEARCH_WITHDRAWALS_FAILURE";

export const FETCH_SINGLE_WITHDRAWALS_START = "FETCH_SINGLE_WITHDRAWALS_START";
export const FETCH_SINGLE_WITHDRAWALS_SUCCESS =
  "FETCH_SINGLE_WITHDRAWALS_SUCCESS";
export const FETCH_SINGLE_WITHDRAWALS_FAILURE =
  "FETCH_SINGLE_WITHDRAWALS_FAILURE";

// Registeration verify.
export const REGISTER_VERIFY_START = "REGISTER_VERIFY_START";
export const REGISTER_VERIFY_SUCCESS = "REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_FAILURE = "REGISTER_VERIFY_FAILURE";

export const REGISTER_VERIFY_RESEND_START = "REGISTER_VERIFY_RESEND_START";
export const REGISTER_VERIFY_RESEND_SUCCESS = "REGISTER_VERIFY_RESEND_SUCCESS";
export const REGISTER_VERIFY_RESEND_FAILURE = "REGISTER_VERIFY_RESEND_FAILURE";

// Pages
export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Notification on or off
export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Notification constant
export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

// Wishlists constant
export const GET_WISHLIST_START = "GET_WISHLIST_START";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAILURE = "GET_WISHLIST_FAILURE";

export const ADD_WISHLIST_START = "ADD_WISHLIST_START";
export const ADD_WISHLIST_SUCCESS = "ADD_WISHLIST_SUCCESS";
export const ADD_WISHLIST_FAILURE = "ADD_WISHLIST_FAILURE";

export const CLEAR_WISHLIST_START = "CLEAR_WISHLIST_START";
export const CLEAR_WISHLIST_SUCCESS = "CLEAR_WISHLIST_SUCCESS";
export const CLEAR_WISHLIST_FAILURE = "CLEAR_WISHLIST_FAILURE";

// Documents constant
export const FETCH_KYC_DOCUMENTS_START = "FETCH_KYC_DOCUMENTS_START";
export const FETCH_KYC_DOCUMENTS_SUCCESS = "FETCH_KYC_DOCUMENTS_SUCCESS";
export const FETCH_KYC_DOCUMENTS_FAILURE = "FETCH_KYC_DOCUMENTS_FAILURE";

export const SAVE_KYC_DOCUMENT_START = "SAVE_KYC_DOCUMENT_START";
export const SAVE_KYC_DOCUMENT_SUCCESS = "SAVE_KYC_DOCUMENT_SUCCESS";
export const SAVE_KYC_DOCUMENT_FAILURE = "SAVE_KYC_DOCUMENT_FAILURE";

export const DELETE_KYC_DOCUMENT_START = "DELETE_KYC_DOCUMENT_START";
export const DELETE_KYC_DOCUMENT_SUCCESS = "DELETE_KYC_DOCUMENT_SUCCESS";
export const DELETE_KYC_DOCUMENT_FAILURE = "DELETE_KYC_DOCUMENT_FAILURE";

// Vehicles related constants
export const SEARCH_VEHICLES_START = "SEARCH_VEHICLES_START";
export const SEARCH_VEHICLES_SUCCESS = "SEARCH_VEHICLES_SUCCESS";
export const SEARCH_VEHICLES_FAILURE = "SEARCH_VEHICLES_FAILURE";

export const TRENDING_VEHICLES_START = "TRENDING_VEHICLES_START";
export const TRENDING_VEHICLES_SUCCESS = "TRENDING_VEHICLES_SUCCESS";
export const TRENDING_VEHICLES_FAILURE = "TRENDING_VEHICLES_FAILURE";

export const TOP_SERVICE_LOCATIONS_START = "TOP_SERVICE_LOCATIONS_START";
export const TOP_SERVICE_LOCATIONS_SUCCESS = "TOP_SERVICE_LOCATIONS_SUCCESS";
export const TOP_SERVICE_LOCATIONS_FAILURE = "TOP_SERVICE_LOCATIONS_FAILURE";

// Bookings constants

export const FETCH_BOOKING_HISTORY_START = "FETCH_BOOKING_HISTORY_START";
export const FETCH_BOOKING_HISTORY_SUCCESS = "FETCH_BOOKING_HISTORY_SUCCESS";
export const FETCH_BOOKING_HISTORY_FAILURE = "FETCH_BOOKING_HISTORY_FAILURE";

export const FETCH_BOOKING_UPCOMING_START = "FETCH_BOOKING_UPCOMING_START";
export const FETCH_BOOKING_UPCOMING_SUCCESS = "FETCH_BOOKING_UPCOMING_SUCCESS";
export const FETCH_BOOKING_UPCOMING_FAILURE = "FETCH_BOOKING_UPCOMING_FAILURE";

export const FETCH_BOOKING_CANCELLED_START = "FETCH_BOOKING_CANCELLED_START";
export const FETCH_BOOKING_CANCELLED_SUCCESS =
  "FETCH_BOOKING_CANCELLED_SUCCESS";
export const FETCH_BOOKING_CANCELLED_FAILURE =
  "FETCH_BOOKING_CANCELLED_FAILURE";

export const FETCH_BOOKING_VIEW_START = "FETCH_BOOKING_VIEW_START";
export const FETCH_BOOKING_VIEW_SUCCESS = "FETCH_BOOKING_VIEW_SUCCESS";
export const FETCH_BOOKING_VIEW_FAILURE = "FETCH_BOOKING_VIEW_FAILURE";

export const CREATE_BOOKING_START = "CREATE_BOOKING_START";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";

export const BOOKING_CHECKIN_START = "BOOKING_CHECKIN_START";
export const BOOKING_CHECKIN_SUCCESS = "BOOKING_CHECKIN_SUCCESS";
export const BOOKING_CHECKIN_FAILURE = "BOOKING_CHECKIN_FAILURE";

export const BOOKING_CHECKOUT_START = "BOOKING_CHECKOUT_START";
export const BOOKING_CHECKOUT_SUCCESS = "BOOKING_CHECKOUT_SUCCESS";
export const BOOKING_CHECKOUT_FAILURE = "BOOKING_CHECKOUT_FAILURE";

export const BOOKING_RATE_START = "BOOKING_RATE_START";
export const BOOKING_RATE_SUCCESS = "BOOKING_RATE_SUCCESS";
export const BOOKING_RATE_FAILURE = "BOOKING_RATE_FAILURE";

// Transaction constants.

export const FETCH_ALL_TRANSACTION_START = "FETCH_ALL_TRANSACTION_START";
export const FETCH_ALL_TRANSACTION_SUCCESS = "FETCH_ALL_TRANSACTION_SUCCESS";
export const FETCH_ALL_TRANSACTION_FAILURE = "FETCH_ALL_TRANSACTION_FAILURE";

export const FETCH_SENT_PAYMENT_TRANSACTION_START =
  "FETCH_SENT_PAYMENT_TRANSACTION_START";
export const FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_SENT_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_SENT_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_RECEIVED_PAYMENT_TRANSACTION_START =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_START";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_DEPOSIT_TRANSACTION_START =
  "FETCH_DEPOSIT_TRANSACTION_START";
export const FETCH_DEPOSIT_TRANSACTION_SUCCESS =
  "FETCH_DEPOSIT_TRANSACTION_SUCCESS";
export const FETCH_DEPOSIT_TRANSACTION_FAILURE =
  "FETCH_DEPOSIT_TRANSACTION_FAILURE";

// verification document

export const FETCH_VERI_DOCUMENT_START = "FETCH_VERI_DOCUMENT_START";
export const FETCH_VERI_DOCUMENT_SUCCESS = "FETCH_VERI_DOCUMENT_SUCCESS";
export const FETCH_VERI_DOCUMENT_FAILURE = "FETCH_VERI_DOCUMENT_FAILURE";

export const SAVE_VERI_DOC_START = "SAVE_VERI_DOC_START";
export const SAVE_VERI_DOC_SUCCESS = "SAVE_VERI_DOC_SUCCESS";
export const SAVE_VERI_DOC_FAILURE = "SAVE_VERI_DOC_FAILURE";

export const DEL_VERI_DOC_START = "DEL_VERI_DOC_START";
export const DEL_VERI_DOC_SUCCESS = "DEL_VERI_DOC_SUCCESS";
export const DEL_VERI_DOC_FAILURE = "DEL_VERI_DOC_FAILURE";

export const VERI_STATUS_CHECK_START = "VERI_STATUS_CHECK_START";
export const VERI_STATUS_CHECK_SUCCESS = "VERI_STATUS_CHECK_SUCCESS";
export const VERI_STATUS_CHECK_FAILURE = "VERI_STATUS_CHECK_FAILURE";

export const DELETE_ALL_VERI_DOC_START = "DELETE_ALL_VERI_DOC_START";
export const DELETE_ALL_VERI_DOC_SUCCESS = "DELETE_ALL_VERI_DOC_SUCCESS";
export const DELETE_ALL_VERI_DOC_FAILURE = "DELETE_ALL_VERI_DOC_FAILURE";

export const FETCH_SINGLE_VERI_DOC_START = "FETCH_SINGLE_VERI_DOC_START";
export const FETCH_SINGLE_VERI_DOC_SUCCESS = "FETCH_SINGLE_VERI_DOC_SUCCESS";
export const FETCH_SINGLE_VERI_DOC_FAILURE = "FETCH_SINGLE_VERI_DOC_FAILURE";

// Subscription constants

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";
export const FETCH_MORE_SUBSCRIPTION_START = "FETCH_MORE_SUBSCRIPTION_START";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_STRIPE_START =
  "SUBSCRIPTION_PAYMENT_STRIPE_START";
export const SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS =
  "SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS";
export const SUBSCRIPTION_PAYMENT_STRIPE_FAILURE =
  "SUBSCRIPTION_PAYMENT_STRIPE_FAILURE";

export const SUBSCRIPTION_PAYMENT_WALLET_START =
  "SUBSCRIPTION_PAYMENT_WALLET_START";
export const SUBSCRIPTION_PAYMENT_WALLET_SUCCESS =
  "SUBSCRIPTION_PAYMENT_WALLET_SUCCESS";
export const SUBSCRIPTION_PAYMENT_WALLET_FAILURE =
  "SUBSCRIPTION_PAYMENT_WALLET_FAILURE";

export const SUBSCRIPTION_AUTO_RENEWAL_START =
  "SUBSCRIPTION_AUTO_RENEWAL_START";
export const SUBSCRIPTION_AUTO_RENEWAL_SUCCESS =
  "SUBSCRIPTION_AUTO_RENEWAL_SUCCESS";
export const SUBSCRIPTION_AUTO_RENEWAL_FAILURE =
  "SUBSCRIPTION_AUTO_RENEWAL_FAILURE";

export const SUBSCRIPTION_COUPON_CHECK_START =
  "SUBSCRIPTION_COUPON_CHECK_START";
export const SUBSCRIPTION_COUPON_CHECK_SUCCESS =
  "SUBSCRIPTION_COUPON_CHECK_SUCCESS";
export const SUBSCRIPTION_COUPON_CHECK_FAILURE =
  "SUBSCRIPTION_COUPON_CHECK_FAILURE";

export const SUBSCRIPTION_CREATE_START = "SUBSCRIPTION_CREATE_START";
export const SUBSCRIPTION_CREATE_SUCCESS = "SUBSCRIPTION_CREATE_SUCCESS";
export const SUBSCRIPTION_CREATE_FAILURE = "SUBSCRIPTION_CREATE_FAILURE";

export const SUBSCRIPTION_STATUS_UPDATE_START =
  "SUBSCRIPTION_STATUS_UPDATE_START";
export const SUBSCRIPTION_STATUS_UPDATE_SUCCESS =
  "SUBSCRIPTION_STATUS_UPDATE_SUCCESS";
export const SUBSCRIPTION_STATUS_UPDATE_FAILURE =
  "SUBSCRIPTION_STATUS_UPDATE_FAILURE";

export const SUBSCRIPTION_MAKE_DEFAULT_START =
  "SUBSCRIPTION_MAKE_DEFAULT_START";
export const SUBSCRIPTION_MAKE_DEFAULT_SUCCESS =
  "SUBSCRIPTION_MAKE_DEFAULT_SUCCESS";
export const SUBSCRIPTION_MAKE_DEFAULT_FAILURE = "SUBSCRIPTION_VIEW_FAILURE";

export const SUBSCRIPTION_VIEW_START = "SUBSCRIPTION_VIEW_START";
export const SUBSCRIPTION_VIEW_SUCCESS = "SUBSCRIPTION_VIEW_SUCCESS";
export const SUBSCRIPTION_VIEW_FAILURE = "SUBSCRIPTION_VIEW_FAILURE";

export const SUBSCRIPTION_DELETE_START = "SUBSCRIPTION_DELETE_START";
export const SUBSCRIPTION_DELETE_SUCCESS = "SUBSCRIPTION_DELETE_SUCCESS";
export const SUBSCRIPTION_DELETE_FAILURE = "SUBSCRIPTION_DELETE_FAILURE";

// Post constants

export const SAVE_POST_START = "SAVE_POST_START";
export const SAVE_POST_SUCCESS = "SAVE_POST_SUCCESS";
export const SAVE_POST_FAILURE = "SAVE_POST_FAILURE";

export const FETCH_POSTS_START = "FETCH_POSTS_START";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";

export const FETCH_SINGLE_POST_START = "FETCH_SINGLE_POST_START";
export const FETCH_SINGLE_POST_SUCCESS = "FETCH_SINGLE_POST_SUCCESS";
export const FETCH_SINGLE_POST_FAILURE = "FETCH_SINGLE_POST_FAILURE";

export const DELETE_POST_START = "DELETE_POST_START";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const CHANGE_POST_STATUS_START = "CHANGE_POST_STATUS_START";
export const CHANGE_POST_STATUS_SUCCESS = "CHANGE_POST_STATUS_SUCCESS";
export const CHANGE_POST_STATUS_FAILURE = "CHANGE_POST_STATUS_FAILURE";

export const POST_FILE_UPLOAD_START = "POST_FILE_UPLOAD_START";
export const POST_FILE_UPLOAD_SUCCESS = "POST_FILE_UPLOAD_SUCCESS";
export const POST_FILE_UPLOAD_FAILURE = "POST_FILE_UPLOAD_FAILURE";

// Chat constant

export const FETCH_CHAT_USERS_START = "FETCH_CHAT_USERS_START";
export const FETCH_CHAT_USERS_SUCCESS = "FETCH_CHAT_USERS_SUCCESS";
export const FETCH_CHAT_USERS_FAILURE = "FETCH_CHAT_USERS_FAILURE";

export const ADD_MESSAGE_CONTENT = "ADD_MESSAGE_CONTENT";

export const FETCH_CHAT_MESSAGE_START = "FETCH_CHAT_MESSAGE_START";
export const FETCH_CHAT_MESSAGE_SUCCESS = "FETCH_CHAT_MESSAGE_SUCCESS";
export const FETCH_CHAT_MESSAGE_FAILURE = "FETCH_CHAT_MESSAGE_FAILURE";

export const SAVE_CHAT_USERS_START = "SAVE_CHAT_USERS_START";
export const SAVE_CHAT_USERS_SUCCESS = "SAVE_CHAT_USERS_SUCCESS";
export const SAVE_CHAT_USERS_FAILURE = "SAVE_CHAT_USERS_FAILURE";

// Comments constant

export const FETCH_COMMENTS_START = "FETCH_COMMENTS_START";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

export const SAVE_COMMENT_START = "SAVE_COMMENT_START";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const SAVE_COMMENT_FAILURE = "SAVE_COMMENT_FAILURE";

export const DELETE_COMMENT_START = "DELETE_COMMENT_START";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

// Bank account

export const ADD_BANK_ACCOUNT_START = "ADD_BANK_ACCOUNT_START";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const VIEW_BANK_ACCOUNT_START = "VIEW_BANK_ACCOUNT_START"
export const VIEW_BANK_ACCOUNT_SUCCESS = "VIEW_BANK_ACCOUNT_SUCCESS"
export const VIEW_BANK_ACCOUNT_FAILURE = "VIEW_BANK_ACCOUNT_FAILURE"

export const GET_BANK_ACCOUNT_START = "GET_BANK_ACCOUNT_START";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT_START = "DELETE_BANK_ACCOUNT_START";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const ADD_BANK_ACCOUNT_DATA = "ADD_BANK_ACCOUNT_DATA";

export const FETCH_SINGLE_BANK_ACCOUNT_START =
  "FETCH_SINGLE_BANK_ACCOUNT_START";
export const FETCH_SINGLE_BANK_ACCOUNT_SUCCESS =
  "FETCH_SINGLE_BANK_ACCOUNT_SUCCESS";
export const FETCH_SINGLE_BANK_ACCOUNT_FAILURE =
  "FETCH_SINGLE_BANK_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BANK_ACCOUNT_START =
  "MAKE_DEFAULT_BANK_ACCOUNT_START";
export const MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BANK_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BANK_ACCOUNT_FAILURE";

export const GET_KYC_DOCUMENT_START = "GET_KYC_DOCUMENT_START";
export const GET_KYC_DOCUMENT_SUCCESS = "GET_KYC_DOCUMENT_SUCCESS";
export const GET_KYC_DOCUMENT_FAILURE = "GET_KYC_DOCUMENT_FAILURE";

export const ADD_KYC_DOCUMENT_START = "ADD_KYC_DOCUMENT_START";
export const ADD_KYC_DOCUMENT_SUCCESS = "ADD_KYC_DOCUMENT_SUCCESS";
export const ADD_KYC_DOCUMENT_FAILURE = "ADD_KYC_DOCUMENT_FAILURE";

// Model search

export const MODEL_SEARCH_START = "MODEL_SEARCH_START";
export const MODEL_SEARCH_SUCCESS = "MODEL_SEARCH_SUCCESS";
export const MODEL_SEARCH_FAILURE = "MODEL_SEARCH_FAILURE";

// Model Details

export const FETCH_SINGLE_MODEL_DETAILS_START =
  "FETCH_SINGLE_MODEL_DETAILS_START";
export const FETCH_SINGLE_MODEL_DETAILS_SUCCESS =
  "FETCH_SINGLE_MODEL_DETAILS_SUCCESS";
export const FETCH_SINGLE_MODEL_DETAILS_FAILURE =
  "FETCH_SINGLE_MODEL_DETAILS_FAILURE";

export const FETCH_MODEL_DETAILS_START = "FETCH_MODEL_DETAILS_START";
export const FETCH_MODEL_DETAILS_SUCCESS = "FETCH_MODEL_DETAILS_SUCCESS";
export const FETCH_MODEL_DETAILS_FAILURE = "FETCH_MODEL_DETAILS_FAILURE";
export const FETCH_MORE_MODEL_DETAILS_START = "FETCH_MORE_MODEL_DETAILS_START";

export const FETCH_MODEL_SUB_START = "FETCH_MODEL_SUB_START";
export const FETCH_MODEL_SUB_SUCCESS = "FETCH_MODEL_SUB_SUCCESS";
export const FETCH_MODEL_SUB_FAILURE = "FETCH_MODEL_SUB_FAILURE";

export const FETCH_MODEL_RATING_START = "FETCH_MODEL_RATING_START";
export const FETCH_MODEL_RATING_SUCCESS = "FETCH_MODEL_RATING_SUCCESS";
export const FETCH_MODEL_RATING_FAILURE = "FETCH_MODEL_RATING_FAILURE";

export const FETCH_MODEL_POSTS_START = "FETCH_MODEL_POSTS_START";
export const FETCH_MODEL_POSTS_SUCCESS = "FETCH_MODEL_POSTS_SUCCESS";
export const FETCH_MODEL_POSTS_FAILURE = "FETCH_MODEL_POSTS_FAILURE";
export const FETCH_MORE_MODEL_POSTS_START = "FETCH_MORE_MODEL_POSTS_START";

export const FETCH_MODEL_SINGLE_POST_START = "FETCH_MODEL_SINGLE_POST_START";
export const FETCH_MODEL_SINGLE_POST_SUCCESS =
  "FETCH_MODEL_SINGLE_POST_SUCCESS";
export const FETCH_MODEL_SINGLE_POST_FAILURE =
  "FETCH_MODEL_SINGLE_POST_FAILURE";

export const FETCH_MODEL_SUGGESTION_START = "FETCH_MODEL_SUGGESTION_START";
export const FETCH_MODEL_SUGGESTION_SUCCESS = "FETCH_MODEL_SUGGESTION_SUCCESS";
export const FETCH_MODEL_SUGGESTION_FAILURE = "FETCH_MODEL_SUGGESTION_FAILURE";

export const FETCH_HOME_START = "FETCH_HOME_START";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAILURE = "FETCH_HOME_FAILURE";

export const MODEL_FILTERS_START = "MODEL_FILTERS_START";
export const MODEL_FILTERS_SUCCESS = "MODEL_FILTERS_SUCCESS";
export const MODEL_FILTERS_FAILURE = "MODEL_FILTERS_FAILURE";

export const FEATURED_MODELS_START = "FEATURED_MODELS_START";
export const FEATURED_MODELS_SUCCESS = "FEATURED_MODELS_SUCCESS";
export const FEATURED_MODELS_FAILURE = "FEATURED_MODELS_FAILURE";

// category action

export const FETCH_CATEGORY_START = "FETCH_CATEGORY_START";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILURE = "FETCH_CATEGORY_FAILURE";

export const FETCH_SUB_CATEGORY_START = "FETCH_SUB_CATEGORY_START";
export const FETCH_SUB_CATEGORY_SUCCESS = "FETCH_SUB_CATEGORY_SUCCESS";
export const FETCH_SUB_CATEGORY_FAILURE = "FETCH_SUB_CATEGORY_FAILURE";

export const FETCH_OCCASION_START = "FETCH_OCCASION_START";
export const FETCH_OCCASION_SUCCESS = "FETCH_OCCASION_SUCCESS";
export const FETCH_OCCASION_FAILURE = "FETCH_OCCASION_FAILURE";

// Booking action

export const BOOKING_SAVE_START = "BOOKING_SAVE_START";
export const BOOKING_SAVE_SUCCESS = "BOOKING_SAVE_SUCCESS";
export const BOOKING_SAVE_FAILURE = "BOOKING_SAVE_FAILURE";

export const BOOKING_CANCEL_START = "BOOKING_CANCEL_START";
export const BOOKING_CANCEL_SUCCESS = "BOOKING_CANCEL_SUCCESS";
export const BOOKING_CANCEL_FAILURE = "BOOKING_CANCEL_FAILURE";

export const BOOKING_FILE_UPLOAD_START = "BOOKING_FILE_UPLOAD_START";
export const BOOKING_FILE_UPLOAD_SUCCESS = "BOOKING_FILE_UPLOAD_SUCCESS";
export const BOOKING_FILE_UPLOAD_FAILURE = "BOOKING_FILE_UPLOAD_FAILURE";

export const FETCH_BOOKINGS_START = "FETCH_BOOKINGS_START";
export const FETCH_MORE_BOOKING_START = "FETCH_MORE_BOOKING_START";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILURE = "FETCH_BOOKINGS_FAILURE";

export const FETCH_SINGLE_BOOKING_START = "FETCH_SINGLE_BOOKING_START";
export const FETCH_SINGLE_BOOKING_SUCCESS = "FETCH_SINGLE_BOOKING_SUCCESS";
export const FETCH_SINGLE_BOOKING_FAILURE = "FETCH_SINGLE_BOOKING_FAILURE";

/// Model Category updation

export const MODEL_CATEGORY_UPDATE_START = "MODEL_CATEGORY_UPDATE_START";
export const MODEL_CATEGORY_UPDATE_SUCCESS = "MODEL_CATEGORY_UPDATE_SUCCESS";
export const MODEL_CATEGORY_UPDATE_FAILURE = "MODEL_CATEGORY_UPDATE_FAILURE";


export const UPDATE_USER_REVIEW_START = "UPDATE_USER_REVIEW_START";
export const UPDATE_USER_REVIEW_SUCCESS = "UPDATE_USER_REVIEW_SUCCESS";
export const UPDATE_USER_REVIEW_FAILURE = "UPDATE_USER_REVIEW_FAILURE";


// Reset Password
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";


// Banner Constents
export const FETCH_BANNERS_START = "FETCH_BANNER_START";
export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";
export const FETCH_BANNERS_FAILURE = "FETCH_BANNERS_FAILURE";

// VideoCall model
export const FETCH_VIDEOCALL_MODEL_START = "FETCH_VIDEOCALL_MODEL_START";
export const FETCH_VIDEOCALL_MODEL_SUCCESS = "FETCH_VIDEOCALL_MODEL_SUCCESS";
export const FETCH_VIDEOCALL_MODEL_FAILURE = "FETCH_VIDEOCALL_MODEL_FAILURE";
export const FETCH_MORE_VIDEOCALL_MODEL_START = "FETCH_MORE_VIDEOCALL_MODEL_START";
export const MODIFY_VIDEOCALL_MODEL_SUCCESS = "MODIFY_VIDEOCALL_MODEL_SUCCESS";

export const GET_VIDEOCALL_MODEL_START = "GET_VIDEOCALL_MODEL_START";
export const GET_VIDEOCALL_MODEL_SUCCESS = "GET_VIDEOCALL_MODEL_SUCCESS";
export const GET_VIDEOCALL_MODEL_FAILURE = "GET_VIDEOCALL_MODEL_FAILURE";

export const STATUS_VIDEOCALL_MODEL_START = "STATUS_VIDEOCALL_MODEL_START";
export const STATUS_VIDEOCALL_MODEL_SUCCESS = "STATUS_VIDEOCALL_MODEL_SUCCESS";
export const STATUS_VIDEOCALL_MODEL_FAILURE = "STATUS_VIDEOCALL_MODEL_FAILURE";

//VideoCall User
export const SEND_VIDEOCALL_USER_START = "SEND_VIDEOCALL_USER_START";
export const SEND_VIDEOCALL_USER_SUCCESS = "SEND_VIDEOCALL_USER_SUCCESS";
export const SEND_VIDEOCALL_USER_FAILURE = "SEND_VIDEOCALL_USER_FAILURE";

export const FETCH_VIDEOCALL_USER_START = "FETCH_VIDEOCALL_USER_START";
export const FETCH_VIDEOCALL_USER_SUCCESS = "FETCH_VIDEOCALL_USER_SUCCESS";
export const FETCH_VIDEOCALL_USER_FAILURE = "FETCH_VIDEOCALL_USER_FAILURE";
export const FETCH_MORE_VIDEOCALL_USER_START = "FETCH_MORE_VIDEOCALL_USER_START";
export const MODIFY_VIDEOCALL_USER_SUCCESS = "MODIFY_VIDEOCALL_USER_SUCCESS";

export const GET_VIDEOCALL_USER_START = "GET_VIDEOCALL_USER_START";
export const GET_VIDEOCALL_USER_SUCCESS = "GET_VIDEOCALL_USER_SUCCESS";
export const GET_VIDEOCALL_USER_FAILURE = "GET_VIDEOCALL_USER_FAILURE";

export const STATUS_VIDEOCALL_USER_START = "STATUS_VIDEOCALL_USER_START";
export const STATUS_VIDEOCALL_USER_SUCCESS = "STATUS_VIDEOCALL_USER_SUCCESS";
export const STATUS_VIDEOCALL_USER_FAILURE = "STATUS_VIDEOCALL_USER_FAILURE";

//VIDEO CALL BOTH
export const JOIN_VIDEOCALL_START = "JOIN_VIDEOCALL_START";
export const JOIN_VIDEOCALL_SUCCESS = "JOIN_VIDEOCALL_SUCCESS";
export const JOIN_VIDEOCALL_FAILURE = "JOIN_VIDEOCALL_FAILURE";

export const END_VIDEOCALL_START = "END_VIDEOCALL_START";
export const END_VIDEOCALL_SUCCESS = "END_VIDEOCALL_SUCCESS";
export const END_VIDEOCALL_FAILURE = "END_VIDEOCALL_FAILURE";

export const START_VIDEOCALL_START = "START_VIDEOCALL_START";
export const START_VIDEOCALL_SUCCESS = "START_VIDEOCALL_SUCCESS";
export const START_VIDEOCALL_FAILURE = "START_VIDEOCALL_FAILURE";

export const VIDEO_CALL_PAYMENT_BY_STRIPE_START = "VIDEO_CALL_PAYMENT_BY_STRIPE_START";
export const VIDEO_CALL_PAYMENT_BY_STRIPE_SUCCESS = "VIDEO_CALL_PAYMENT_BY_STRIPE_SUCCESS";
export const VIDEO_CALL_PAYMENT_BY_STRIPE_FAILURE = "VIDEO_CALL_PAYMENT_BY_STRIPE_FAILURE";

export const FETCH_VIDEO_CALL_VIEW_START = "FETCH_VIDEO_CALL_VIEW_START";
export const FETCH_VIDEO_CALL_VIEW_SUCCESS = "FETCH_VIDEO_CALL_VIEW_SUCCESS";
export const FETCH_VIDEO_CALL_VIEW_FAILURE = "FETCH_VIDEO_CALL_VIEW_FAILURE";


//EVENT MODEL LIST
export const FETCH_EVENT_MODEL_LIST_START = "FETCH_EVENT_MODEL_LIST_START";
export const FETCH_EVENT_MODEL_LIST_SUCCESS = "FETCH_EVENT_MODEL_LIST_SUCCESS";
export const FETCH_EVENT_MODEL_LIST_FAILURE = "FETCH_EVENT_MODEL_LIST_FAILURE";
export const FETCH_MORE_EVENT_MODEL_LIST_START = "FETCH_MORE_EVENT_MODEL_START";

export const GENERATE_STRIPE_PAYMENT_START = "GENERATE_STRIPE_PAYMENT_START";
export const GENERATE_STRIPE_PAYMENT_SUCCESS = "GENERATE_STRIPE_PAYMENT_SUCCESS";
export const GENERATE_STRIPE_PAYMENT_FAILURE = "GENERATE_STRIPE_PAYMENT_FAILURE";

//VIDEO CALL PAYMENT

export const VIDEO_CALLS_PAYMENT_STATUS_START = "VIDEO_CALLS_PAYMENT_STATUS_START";
export const VIDEO_CALLS_PAYMENT_STATUS_SUCCESS = "VIDEO_CALLS_PAYMENT_STATUS_SUCCESS";
export const VIDEO_CALLS_PAYMENT_STATUS_FAILURE = "VIDEO_CALLS_PAYMENT_STATUS_FAILURE";


export const VIDEO_CALLS_GENERATE_STRIPE_PAYMENT_START = "VIDEO_CALLS_GENERATE_STRIPE_PAYMENT_START";
export const VIDEO_CALLS_GENERATE_STRIPE_PAYMENT_SUCCESS = "VIDEO_CALLS_GENERATE_STRIPE_PAYMENT_SUCCESS";
export const VIDEO_CALLS_GENERATE_STRIPE_PAYMENT_FAILURE = "VIDEO_CALLS_GENERATE_STRIPE_PAYMENT_FAILURE";

export const REQUEST_HIRE_AS_INFLUENCER = "REQUEST_HIRE_AS_INFLUENCER";
export const HIRE_AS_INFLUENCER_SUCCESS = "HIRE_AS_INFLUENCER_SUCCESS";
export const HIRE_AS_INFLUENCER_FAILURE = "HIRE_AS_INFLUENCER_FAILURE";










