import React from 'react';
import { setLanguage, getLanguage } from 'react-multi-lang';

const LanguageSwitcher = () => {

    const language = getLanguage();

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        localStorage.setItem('lang', lang);
        window.location.reload();
      };

    return (
        <div style={{ display: 'flex', gap: '10px', marginLeft: "20px" }}>
            <img
                src={window.location.origin + "/assets/images/flags/usa.svg"}
                alt="English"
                onClick={() => handleLanguageChange('en')}
                style={{ 
                    cursor: 'pointer',
                    width: '20px',
                    paddingBottom: '2px',
                    borderBottom: language === 'en' ? '2px solid var(--primary-color)' : 'none',
                }}
            />
            <img
                src={window.location.origin + "/assets/images/flags/portugal.svg"}
                alt="Português"
                onClick={() => handleLanguageChange('pt')}
                style={{
                    cursor: 'pointer',
                    width: '20px',
                    paddingBottom: '2px',
                    borderBottom: language === 'pt' ? '2px solid var(--primary-color)' : 'none',
                }}
            />
            <img
                src={window.location.origin + "/assets/images/flags/spain.svg"}
                alt="Español"
                onClick={() => handleLanguageChange('es')}
                style={{
                    cursor: 'pointer',
                    width: '20px',
                    paddingBottom: '2px',
                    borderBottom: language === 'es' ? '2px solid var(--primary-color)' : 'none',
                }}
            />
            <img
                src={window.location.origin + "/assets/images/flags/france.svg"}
                alt="Français"
                onClick={() => handleLanguageChange('fr')}
                style={{
                    cursor: 'pointer',
                    width: '20px',
                    paddingBottom: '2px',
                    borderBottom: language === 'fr' ? '2px solid var(--primary-color)' : 'none',
                }}
            />
        </div>
    );
};

export default LanguageSwitcher;
