import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import ErrorSaga from "./ErrorSaga";
import BillingAccountSaga from "./BillingAccountSaga";
import NotificationSaga from "./NotificationSaga";
import PageSaga from "./PageSaga";
import WalletSaga from "./WalletSaga";
import TransactionSaga from "./TransactionSaga";
import PostSaga from "./PostSaga";
import VerificationDocumentSaga from "./VerificationDocumentSaga";
import SubscriptionSaga from "./SubscriptionSaga";
import WithDrawSaga from "./WithDrawSaga";
import ChatSaga from "./ChatSaga";
import CommentsSaga from "./CommentsSaga";
import BankAccountSaga from "./BankAccountSaga";
import ModelSaga from "./ModelSaga";
import KycDocumentSaga from "./KycDocumentSaga";
import LookUpSaga from "./LookUpSaga";
import BookingSaga from "./BookingSaga";
import VideoCallSaga from "./VideoCallSaga";

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(BillingAccountSaga)]);
  yield all([fork(ErrorSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(PostSaga)]);
  yield all([fork(VerificationDocumentSaga)]);
  yield all([fork(WalletSaga)]);
  yield all([fork(TransactionSaga)]);
  yield all([fork(SubscriptionSaga)]);
  yield all([fork(WithDrawSaga)]);
  yield all([fork(BankAccountSaga)]);
  yield all([fork(ModelSaga)]);
  yield all([fork(LookUpSaga)]);
  yield all([fork(KycDocumentSaga)]);
  yield all([fork(BookingSaga)]);
  yield all([fork(VideoCallSaga)]);

  //   yield all([fork(ChangePasswordSaga)]);

  //   yield all([fork(TransactionSaga)]);
  //   yield all([fork(WithDrawSaga)]);
  //   yield all([fork(HomeSaga)]);
  //   yield all([fork(ChatSaga)]);
  yield all([fork(NotificationSaga)]);
}
