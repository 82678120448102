import React from "react";
import { t, withTranslation } from "react-multi-lang";

const PurchaseSuccessPage = () => {
  return (
    <div>
      <h1 className="text-center" style={{ color: "white" }}>{t("purchase_successfully")}</h1>
      <p className="text-center" style={{ color: "white" }}>{t("thank_you_for_your_purchase")}<br/>{t("you_will_receive_an_email_when_your_video_is_ready")}</p>
    </div>
  );
};

export default PurchaseSuccessPage;
