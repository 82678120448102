import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Button, Table } from "react-bootstrap";
import "./RequestUploadVideo.css";
import {
  bookingFileUploadStart,
  fetchSingleBookingStart,
} from "../../store/actions/BookingAction";
import { t, withTranslation } from "react-multi-lang";

const RequestUploadVideo = (props) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);

  useEffect(() => {
    props.dispatch(
      fetchSingleBookingStart({
        booking_unique_id: props.match.params.booking_unique_id,
      })
    );
  }, []);

  const handleChangeVideo = (event) => {
    if (event.currentTarget.type === "file") {
      const file = event.currentTarget.files[0];
      if (file && file.type.match("video.*")) {
        setSelectedVideo(file);
        setVideoPreview(URL.createObjectURL(file));
      }
    }
  };

  const handleSubmit = () => {
    if (selectedVideo) {
      props.dispatch(
        bookingFileUploadStart({
          file: selectedVideo,
          file_type: "video",
          booking_id: props.singleBooking.data.booking.booking_id,
        })
      );
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="request-upload-video-sec">
            <div className="request-upload-video-card">
              <h3 className="heading-title mt-0 mb-4">{t("request_upload_video")}</h3>
              <Row>
                <Col md={12}>
                  {props.singleBooking.loading ? (
                    t("loading")
                  ) : (
                    <div className="single-request-view">
                      <div className="single-request-sec">
                        <div className="request-info">
                          <h3>{t("to")}</h3>
                          <p>{props.singleBooking.data.booking.to_username}</p>
                        </div>
                        <div className="request-info">
                          <h3>{t("from")}</h3>
                          <p>{props.singleBooking.data.booking.username}</p>
                        </div>
                        <div className="request-info">
                          <h3>{t("occasion")}</h3>
                          <p>
                            {props.singleBooking.data.booking.occasion_name}
                          </p>
                        </div>
                        <div className="request-info">
                          <h3>{t("my_email")} </h3>
                          <p>{props.singleBooking.data.booking.user.email}</p>
                        </div>
                        <div className="request-info">
                          <h3>{t("text_me_order_updates")}</h3>
                          <p>{props.singleBooking.data.booking.user.mobile}</p>
                        </div>
                        <div className="request-info">
                          <h3>{t("date")}</h3>
                          <p>
                            {props.singleBooking.data.booking.updated_formatted}
                          </p>
                        </div>
                      </div>
                      <div className="single-request-sec-1">
                        <div className="request-info">
                          <h3>{t("instructions")}</h3>
                          <p>{props.singleBooking.data.booking.instructions}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {props.singleBooking.loading ? (
                    t("loading")
                  ) : props.singleBooking.data.booking.status == 4 &&
                    localStorage.getItem("is_model") == 2 ? (
                    <Row>
                      <Col md={12} xl={6}>
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/upload-video.svg"
                          }
                          alt="user-image"
                          className="upload-video-img"
                        />
                        {/* <p className="short-note-desc">
                          Click the upload button for upload the video
                        </p> */}
                      </Col>
                      <Col md={12} xl={6}>
                        <div className="file-upload-wrap">
                          <input
                            type="file"
                            name="files[]"
                            className="multi file-upload-input with-preview"
                            // multiple
                            maxLength="3"
                            onChange={handleChangeVideo}
                          />
                          <span className="file-upload-text">
                            <i className="fa fa-upload mr-2"></i>{t("click_or_drag_file_here_to_upload")}
                          </span>
                        </div>
                        {videoPreview && (
                          <div className="video-preview">
                            <video width="100%" controls>
                              <source src={videoPreview} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        )}
                        <div className="upload-doc-btm-sec">
                          <Button
                            className="theme-btn"
                            type="button"
                            onClick={handleSubmit}
                            disabled={!selectedVideo}
                          >
                            {props.uploading.loadingButtonContent !== null
                              ? props.uploading.loadingButtonContent
                              : t("submit")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    " "
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleBooking: state.bookings.singleBooking,
  uploading: state.bookings.uploading,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(RequestUploadVideo));
